// Style
import "@/assets/styles/index.scss";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { vfmPlugin } from 'vue-final-modal'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencil, faFloppyDisk, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

library.add(faPencil, faFloppyDisk, faCircleXmark);

const app = createApp(App);
  app.component('font-awesome-icon', FontAwesomeIcon)
  app.use(store)
  app.use(router)
  app.use(vfmPlugin)
  app.mount('#app')
