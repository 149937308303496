<template>
  <nav>
    <router-link to="/user">Mes cartes</router-link> |
    <router-link to="/admin">Admin</router-link>
  </nav>
  <router-view></router-view>
</template>

<style lang="scss">

body {
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
